<template lang="pug">
  main#main.oauth-authorize.d-flex.flex-grow-1.justify-content-center
    b-container
      b-row
        b-col.mx-auto(sm='10' md='8' lg='6')
          section.l-section
            .l-section-h
              b-card.card-transparent(v-if='isFormData')
                .pb-5.text-center
                  .wc-i.wc-symbol.rounded(style='width: 80px; height: 80px')

                .text-center.text-white.mb-4
                  h3
                    i.fal.fa-fw.fa-shield-check
                    | {{ $t('doorkeeper.authorizations.new.title') }}

                .text-center.mb-4
                  h5
                    | {{ $t('doorkeeper.authorizations.new.prompt', { client_name: form.client_name }) }}
                    |  {{ $t('doorkeeper.authorizations.new.able_to') }}:

                  h5.text-secondary {{ form.scope }}

                b-form.form-wc.form-authorize.mb-3(@submit='formAuthorize')
                  b-button.text-uppercase(type='submit' variant='secondary' block)
                    i.fal.fa-fw.fa-check
                    | {{ $t('doorkeeper.authorizations.buttons.authorize') }}

                b-form.form-wc.form-deny(@submit='formDeny')
                  b-button.text-uppercase(type='submit' variant='primary' block)
                    i.fal.fa-fw.fa-times
                    | {{ $t('doorkeeper.authorizations.buttons.deny') }}

    loading(:opacity='0' :active.sync='isLoading')
</template>

<script>
import apiOauth from '@services/api/oauth'

export default {
  name: 'oauth-authorize',
  methods: {
    formFill() {
      this.isLoading = true
      this.apiRequest = apiOauth.new(this.$http, {}, this.$route.query)
      this.apiRequest.promise
        .then((response) => {
          if (!this.formRedirect(response)) {
            this.isFormData = true
            this.isLoading = false
          }
          return true
        })
        .catch(() => {
          this.isFormData = false
          this.isLoading = false
          /* TODO show error (e.g. invalid scope) */
        })
    },
    formAuthorize() {
      event.preventDefault()
      this.isLoading = true
      this.apiRequest = apiOauth.authorize(this.$http, {}, this.$route.query)
      this.apiRequest.promise
        .then((response) => {
          this.isLoading = false
          return this.formRedirect(response)
        })
        .catch((response) => {
          this.isLoading = false
          this.formRedirect(response)
        })
    },
    formDeny() {
      event.preventDefault()
      this.isLoading = true
      this.apiRequest = apiOauth.deny(this.$http, {}, { params: this.$route.query })
      this.apiRequest.promise
        .then((response) => {
          this.isLoading = false
          return this.formRedirect(response)
        })
        .catch((response) => {
          this.isLoading = false
          this.formRedirect(response)
        })
    },
    formRedirect(response) {
      if (response.status === 'redirect' && response.redirect_uri) {
        const url = new URL(response.redirect_uri)
        if (this.$route.query.redirect_to)
          url.searchParams.append('redirect_to', this.$route.query.redirect_to)
        window.location.replace(url)
        return true
      }
      return false
    },
  },
  data() {
    return {
      apiRequest: null,
      isLoading: true,
      isFormData: false,
      form: {},
    }
  },
  mounted() {
    /* Only on client side. */
    this.formFill()
  },
}
</script>
