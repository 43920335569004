import api from '@services/api'

export default {
  new(axios, params, query, config) {
    return api.get(axios, '/user/authorize', params, query, {
      ...config,
      baseURL: process.server
        ? process.env.VUE_APP_WC_AUTH_OAUTH_IURL
        : process.env.VUE_APP_WC_AUTH_OAUTH_URL,
    })
  },

  authorize(axios, params, payload, config) {
    return api.post(axios, '/user/authorize', params, payload, {
      ...config,
      baseURL: process.server
        ? process.env.VUE_APP_WC_AUTH_OAUTH_IURL
        : process.env.VUE_APP_WC_AUTH_OAUTH_URL,
    })
  },

  deny(axios, params, config) {
    return api.delete(axios, '/user/authorize', params, {
      ...config,
      baseURL: process.server
        ? process.env.VUE_APP_WC_AUTH_OAUTH_IURL
        : process.env.VUE_APP_WC_AUTH_OAUTH_URL,
    })
  },
}
